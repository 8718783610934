// extracted by mini-css-extract-plugin
export var container = "painting-module--container--2IYlZ";
export var painting = "painting-module--painting--2w_6u";
export var text = "painting-module--text--3MUFp";
export var title = "painting-module--title--f-D4n";
export var title_cont = "painting-module--title_cont--uy_ly";
export var image = "painting-module--image--2aqXE";
export var zoomedIn = "painting-module--zoomedIn--3x0qK";
export var zoomedOut = "painting-module--zoomedOut--264IH";
export var extraCont = "painting-module--extraCont--2waxo";
export var extra = "painting-module--extra--2OU3A";
export var selected = "painting-module--selected--XFwvs";