import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Link, navigate } from "gatsby";
import { Helmet } from 'react-helmet'


import * as paintingStyles from "./painting.module.css";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { myContext } from '../components/PageContext';
import { BiDotsVertical, BiCaretLeft, BiCaretRight } from 'react-icons/bi'
import { AiOutlineRollback } from 'react-icons/ai'


export default function Painting({ data, pageContext: { title, key, year, pageCont, medium, size, price, availablePainting } }) {  
  const pageInfo = data.pageInfo.edges.find(item => item.node.id === `SitePage /available/${title.toLowerCase().replace(/ /g,"-")}`)
  const image = data.painting.localFile
  let framedImage = false
  if (data.framedPainting) {
    framedImage = data.framedPainting.localFile
   }

  const originWidth = image.childImageSharp.original.width

  const [clicked, setClicked] = useState(true)

  const context = useContext(myContext);
  const myRef = useRef(null)

  const [currentSrc, setCurrentSrc] = useState(image)

  function changeSrc(e) {
    currentSrc === image ? setCurrentSrc(framedImage) : setCurrentSrc(image)
  }

  useEffect(() => {
    context.sideNavOff()
  },[])

  return (
    <>
    <Helmet htmlAttributes={{ lang : 'en-uk' }}>
      <meta charSet="utf-8" />
      <title>Alan Macdonald</title>
      <meta charSet="utf-8" />
      <meta name="description" content={`Alan Macdonald`}/>
      <meta name="keywords" content={`Alan Macdonald`}/>
      <meta name="author" content="Alan Macdonald"/>
      <meta property="og:title" content={'Alan Macdonald'} />
      <meta property="og:description" content={'Alan Macdonald'} />
      <meta property="og:image" content={data.ogimage.localFile.childImageSharp.resize.src} />

    <meta property="twitter:title" content="Alan Macdonald"/>
    <meta property="twitter:description" content={image.name}/>


      <link rel="canonical" href="" />
    </Helmet>
    <Layout current={null}>
      <main>
      <myContext.Consumer>
    {context => (
      <div>
        <div style={{position: 'absolute', top: 'calc(50px - 9px)', left: '1rem'}}>
        <button onClick={() => navigate(`/available/all#${pageInfo.node.path.substring(11)}`)}><AiOutlineRollback size={18} /></button>
      </div>
      </div>
      
    )}
    </myContext.Consumer>
      <div className={paintingStyles.container} style={{marginTop: "35px"}}>
      <div ref={myRef} className={paintingStyles.text}>
        {pageInfo.previous ? <Link to={`${pageInfo.previous.path}`}><BiCaretLeft size={24}/></Link> : <Link><BiCaretLeft color={"#423e3e"} size={24}/></Link>}
        {pageInfo.next ? <Link to={`${pageInfo.next.path}`}><BiCaretRight size={24} /></Link> : <Link><BiCaretRight color={"#423e3e"} size={24}/></Link>}
      </div>
      <div className={paintingStyles.painting}>
      <GatsbyImage
          onClick={() => clicked ? setClicked(false) : setClicked(true)}
          style={{width: originWidth + 'px'}}
          className={`${clicked ? paintingStyles.zoomedOut : paintingStyles.zoomedIn} ${paintingStyles.image}`}
          image={getImage(currentSrc)}
          alt={"placeholder"}
        />
      </div>
      {data.framedPainting ?
      <div className={paintingStyles.extraCont}>
      <button onClick={() => setCurrentSrc(image)} className={`${paintingStyles.extra} ${currentSrc == image ? paintingStyles.selected : ''}`}>
        <GatsbyImage
          image={getImage(image)}
          alt={"placeholder"}
        />
    </button>
    <button onClick={() => setCurrentSrc(framedImage)} className={`${paintingStyles.extra} ${currentSrc == framedImage ? paintingStyles.selected : ''}`}>
        <GatsbyImage
          image={getImage(framedImage)}
          alt={"placeholder"}
        />
    </button>
      {/* <ExtraContent src={image} onClick={e => changeSrc(e)} /> */}
      {/* <ExtraContent src={image} onClick={e => changeSrc(e)} /> */}
      </div> 
    : null}
      <div className={`${paintingStyles.title} ${paintingStyles.title_cont}`}>
        <h2>{title}</h2>
        <p>{year}</p>
        <p>{String(size.split("x").map(i => i + "cm").join(" × "))} / {String(size.split("x").map(i => Math.round((i/2.54) * 10) / 10 + "\"").join(" × "))}</p>
        <p>{medium[0].toUpperCase() + medium.substring(1)}</p>
        <p>£{price}</p>
      </div>
      </div>
      </main>
    </Layout>
    </>
  );
}
export const query = graphql`
query ($key: String!, $framed: String!) {
  pageInfo: allSitePage(
    filter: {context: {availablePainting: {eq: true}}}
  ) {
    edges {
      node {
        id
        path
      }
      previous {
        path
        context {
          key
        }
      }
      next {
        path
        context {
          key
        }
      }
    }
  }
  painting: s3Object(Key: {eq: $key}) {
    Key
    localFile {
      relativePath
      childImageSharp {
        original {
          width
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
      }
    }
  }
  framedPainting: s3Object(Key: {eq: $framed}) {
    Key
    localFile {
      relativePath
      childImageSharp {
        original {
          width
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
      }
    }
  }
  ogimage: s3Object(Key: {eq: $key}) {
    localFile {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
}
`;

    // paintingsJson( title: { eq: $title } ) {
    //   title,
    //   filepath,
    //   year
    // }